<template>
    <div class="scrollIndicator m-a">
        <span class="icon icon-down_d"></span>
    </div>
</template>

<script>
export default {
    name: 'SidebarScrollIndicator',
    components: {},
    data() {
        return {
            scroll: 0
        }
    },
    // methods: {
    // },
    // props: {
    // }
}
</script>