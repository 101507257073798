<template>
  <div class="mapSidebar">
    <div class="row aic">
      <div class="mapSidebarZoomInfo col-12 ta-c p-2">
        <div class="icon desktop"><img src="@/assets/dist/img/zoomMap.svg" alt="zoomMap"></div>
        <h5 class="mb-0">{{$t('zoom_in_to_see_details')}}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapSidebarNotSelected',
  props: {
    notSelected: {
      type: Boolean,
      default: false
    }
  }
}

</script>