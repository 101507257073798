<template>
    <div class="links col-12 py-2">
        <div class="row aic">
            <div class="linksTitle col-12 px-2 pt-2">
                <h2 class="h3_mob">
                    <span class="icon"><img src="@/assets/dist/img/linksIcon03.svg" :alt="$t('results.sidebar.save.title')"></span> 
                    <span class="val">{{$t('results.sidebar.save.title')}}</span>
                </h2>

                <p>{{$t('results.sidebar.save.desc')}}</p>

                <div v-if="this.link != null && 0">
                  <input class="copy-input" type="text" ref="linkInput" v-bind:value="this.link" />
                </div>
                <a @click="this.copyTextToClipboard" class="btn btn-full" download><span class="val">{{$t('results.sidebar.save.save')}}</span> <span class='icon icon-link'></span></a>
                <div class="copy-message" v-bind:class="{error: this.copyMessageError, success: this.copyMessageSuccess}">
                  {{this.copyMessage}}

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SaveSection',
    components: {
    },
    props: {
    },
    data() {
      return {
        copyMessage: '',
        copyMessageError: false,
        copyMessageSuccess: false,
        link: null
      }
    },
    watch: {
      '$store.state.lang': function () {
        this.handleSave();
      }
    },
    created() {
      this.handleSave();
    },
    methods: {
      handleSave() {
        this.$store
            .dispatch('saveConfig', this.$store.state.quiz.uuid)
            .then(result => {
              this.link = process.env.VUE_APP_ADDRESS + '/' + this.$store.state.lang + '/wynik/' +result.data.uuid;
            }).catch(error => {
          console.log(error);
        });

        this.$emit('analyticsEvent', 'save');
      },
      copyTextToClipboard(event) {
        this.InlineButtonClickHandler(event);
        var thisComponent = this;
        if (!navigator.clipboard) {
          this.copyMessage = this.$t('browser_not_support_copy');
          this.copyMessageError = true;
          this.copyMessageSuccess = false;
          return;
        }
        navigator.clipboard.writeText(thisComponent.link).then(function() {
          thisComponent.copyMessage = thisComponent.$t('copy_success');// + ': ' + text;
          thisComponent.copyMessageError = false;
          thisComponent.copyMessageSuccess = true;
        }, function(e) {
          thisComponent.copyMessage = thisComponent.$t('copy_error') + ': ' + e.toString();
          thisComponent.copyMessageError = true;
          thisComponent.copyMessageSuccess = false;
        });
        this.handleSave();
      },
      InlineButtonClickHandler(event) {
        event.section = 'Summary Save';
        this.$emit('sateliteClick', event);
      }
    }
}
</script>
