import { createStore } from 'vuex'
import axios from "axios";
//import createPersistedState from "vuex-persistedstate";


export default createStore({
    state: {
        lang: 'pl',
        pages: [],
        pagesNotFound: [],
        pagesLoading: [],
        articles: [],
        articlesNotFound: [],
        articlesLoading: [],
        menuItems: [],
        links: [],
        aboutSections: [],
        quiz: {
            question_1: null,
            city: null,
            address: null,
            industry: null,
            budget: null,
            noBudgetYet: false,
            buy: false,
            uuid: null
        },
        cities: [],
        industries: [],
        helpOpen: true
    },
    getters: {
        getQuizPathName(state) {
            if(state.quiz.question_1 == 1)
                return 'a';

            if(state.quiz.question_1 == 2)
                return 'b';

            if(state.quiz.question_1 == 3)
                return 'c';

            if(state.quiz.question_1 == 4)
                return 'd';
        }
    },
    mutations: {
        setHelp(state, help) {
          state.helpOpen = help;
        },
        setPage(state, page) {
          if(page.isHome == true)
            state.pages['home'] = page;
          else
            state.pages[page.slug] = page;

        },
        setArticle(state, article) {

            state.articles[article.slug] = article;

        },
        setArticles(state, articles) {
          state.articles = articles;
        },
        setMenu(state, menu) {
          state.menuItems = menu;
        },
        setLinks(state, links) {
          state.links = links;
        },
        setAboutSections(state, sections) {
          state.aboutSections = sections;
        },
        setQuizQuestion1(state, question) {
            state.quiz.question_1 = question;
            if(state.quiz.question_1 == 1) {
                state.quiz.industry = null;
            }
            if(state.quiz.question_1 == 2 || state.quiz.question_1 == 3) {
                state.quiz.address = null;
            }
        },
        setQuizCity(state, city) {
            state.quiz.city = city;
        },
        setQuizAddress(state, address) {
            state.quiz.address = address;
        },
        setQuizIndustry(state, industry) {
            console.log(industry);
            state.quiz.industry = industry;
        },
        setQuizBudget(state, budget) {
            state.quiz.budget = budget;
        },
        setQuizBuy(state, buy) {
            state.quiz.buy = buy;
        },
        setQuizNoBudgetYet(state, noBudgetYet) {
            state.quiz.noBudgetYet = noBudgetYet;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        setIndustries(state, industries) {
            state.industries = industries;
        },
        setUuid(state, uuid) {
            state.quiz.uuid = uuid;
        },
        setQuizProperty(state, item) {
            state.quiz[item.field] = item.value;
        },
    },
    actions: {
        changeLang(store, lang) {
          store.state.lang = lang;
        },
        fetchPage(store, slug) {

          if(store.state.pagesLoading.includes(slug)) {
            return;
          }
          if(store.state.pages[slug] !== undefined) {
            return store.state.pages[slug]
          }
          if(store.state.pagesNotFound.includes(slug)) {
            return null;
          }

          store.state.pagesLoading.push(slug);

          return fetch(process.env.VUE_APP_API_URL + '/page/' + slug)
              .then(response => response.json())
              .then(data=> {

                store.commit('setPage', data);
                store.state.pagesLoading = store.state.pagesLoading.filter(item => item !== slug);
                return data
              }).catch(() => {

                store.state.pagesNotFound.push(slug);
                store.state.pagesLoading = store.state.pagesLoading.filter(item => item !== slug);
              });
        },
        fetchArticle(store, slug) {

            if(store.state.articlesLoading.includes(slug)) {
                return;
            }
            if(store.state.articles[slug] !== undefined) {
                return store.state.articles[slug]
            }
            if(store.state.articlesNotFound.includes(slug)) {
                return null;
            }

            store.state.articlesLoading.push(slug);

            return fetch(process.env.VUE_APP_API_URL + '/article/' + slug)
                .then(response => response.json())
                .then(data=> {

                    store.commit('setArticle', data);
                    store.state.articlesLoading = store.state.articlesLoading.filter(item => item !== slug);
                    return data
                }).catch(() => {

                    store.state.articlesNotFound.push(slug);
                    store.state.articlesLoading = store.state.articlesLoading.filter(item => item !== slug);
                });
        },
        fetchArticles(store) {
            return fetch(process.env.VUE_APP_API_URL + '/articles/')
                .then(response => response.json())
                .then(data=> {

                    store.commit('setArticles', data);
                    return data
                });
        },
        fetchMenu(store, sectionName) {
          if(store.state.menuItems.length > 0) {
            return store.state.menuItems;
          }
          return fetch(process.env.VUE_APP_API_URL + '/menu/'+sectionName)
              .then(response => response.json())
              .then(data=> {

                store.commit('setMenu', data);
                return data
              }).catch(() => {
              });
        },
        fetchLinks(store) {
            if(store.state.links.length > 0) {
              return store.state.links;
            }
            return fetch(process.env.VUE_APP_API_URL + '/links/')
                .then(response => response.json())
                .then(data=> {

                  store.commit('setLinks', data);
                  return data
                }).catch(() => {
            });
        },
        fetchAboutSections(store) {
          if(store.state.aboutSections.length > 0) {
              return store.state.aboutSections;
          }
          return fetch(process.env.VUE_APP_API_URL + '/about-sections/')
              .then(response => response.json())
              .then(data=> {

                  store.commit('setAboutSections', data);
                  return data;
              });
        },
        sendContactForm(store, data) {
            return axios.post(process.env.VUE_APP_API_URL + '/contact-form/', data)
                .then((res) => {

                    return res;
            });
        },
        reverseGeolocation(store, data) {

            return fetch(process.env.VUE_APP_API_URL + '/address-by-coords/'+'?lat='+data.lat+'&lng='+data.lng)
                .then(response => response.json())
                .then(data=> {

                    //store.commit('setAboutSections', data);
                    return data;
                });
        },
        fetchCities(store) {
            return fetch(process.env.VUE_APP_API_URL + '/cities/')
                .then(response => response.json())
                .then(data=> {

                    store.commit('setCities', data);
                    return data
                });
        },
        fetchCity(store, id) {
            return fetch(process.env.VUE_APP_API_URL + '/city/'+id)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchSquares(store, data) {
            return fetch(process.env.VUE_APP_API_URL + '/squares/'+data.cityId)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchIndustries(store) {
            return fetch(process.env.VUE_APP_API_URL + '/industries/')
                .then(response => response.json())
                .then(data=> {

                    store.commit('setIndustries', data);
                    return data
                });
        },
        fetchSquareOffers(store, query) {
            return fetch(process.env.VUE_APP_API_URL + '/square-offers/'+query.squareId+'?budget='+query.budget+'&noBudgetYet='+query.noBudgetYet+'&buy='+query.buy)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchSquareScoring(store, query) {
            return fetch(process.env.VUE_APP_API_URL + '/square-scoring/'+query.squareId)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchSquareDensity(store, query) {
            return fetch(process.env.VUE_APP_API_URL + '/square-density/'+query.squareId)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchScoring(store, data) {
            return fetch(process.env.VUE_APP_API_URL + '/scoring/'+data.cityId+'/'+data.categoryId)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },

        fetchAutocompleteAddress(store, data) {
            var api_url = 'https://api.maptiler.com/geocoding/';
            var searching_lang = store.state.lang == 'en' ? 'pl' : store.state.lang;
            var params = 'types=address&country=pl&language='+searching_lang+'&proximity='+data.lng+','+data.lat+'&fuzzyMatch=true&limit=10&key='+process.env.VUE_APP_MAPTILER_API_KEY;

            return fetch(api_url + data.address+'.json?'+params)
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        fetchOnboarding() {
            return fetch(process.env.VUE_APP_API_URL + '/onboarding')
                .then(response => response.json())
                .then(data=> {
                    return data
                });
        },
        saveConfig(store, uuid = null) {
            return axios.post(process.env.VUE_APP_API_URL + '/save' + (uuid != null ? '/' + uuid : ''), store.state.quiz)
                .then((res) => {

                    store.commit("setUuid", res.data.uuid);

                    return res;
                }).catch((err) => {
                    console.log(err);
                    if(err.response.status == 404) {
                        store.commit("setUuid", null);
                    }
                });
        },
        getConfig(store, uuid) {
            return fetch(process.env.VUE_APP_API_URL + '/get/' + uuid)
                .then(response => response.json())
                .then(data=> {

                    store.commit('setUuid', uuid);
                    for(var i in data.data) {
                        store.commit('setQuizProperty', {
                            'field': i,
                            'value': data.data[i]
                        });
                    }

                    return data.data;
                })
        }
    },
    modules: {
    },
    //plugins: [createPersistedState()],
})
