<template>
    <div class="aboutList aboutListSlider col-12 px-1">
        <carousel 
            ref="aboutListCarousel"
            v-model="aboutListCurrentSlide" 
            v-bind="aboutListSettings" 
            :breakpoints="aboutListBreakpoints"
            :transition="500"
            :wrap-around="true"
            >

            <slide v-for="(single, idx) in aboutObject" 
                :key="'aboutListSingle_' + idx">
                <GlobalAboutListSingle
                    :id="single.id"
                    :icon="single.image"
                    :title="this.getSingleTitle(single)"
                    :desc="this.getSingleDescription(single)"
                    :single="single"
                    />
            </slide>
            
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>

        <div class="aboutListSliderNav">
            <a href="#" @click.stop.prevent="prev"><span class='icon icon-prev'></span></a>
            <!-- <input type="number" min="0" max="9" v-model="currentSlide" /> -->
            <!-- <div>{{ currentSlide }}</div> -->
            <a href="#" @click.stop.prevent="next"><span class='icon icon-next'></span></a>
        </div>
    </div>
</template>

<script>
import GlobalAboutListSingle from '@/components/GlobalAboutListSingle.vue';

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'AboutListSlider',
    data() {
        return {
            // carousel settings
            aboutListCurrentSlide: 1,
            aboutListSettings: {
                itemsToShow: 1,
                snapAlign: 'end',
            },
            aboutListBreakpoints: {
                390: {
                    itemsToShow: 1.3,
                    snapAlign: 'end',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },
            // carousel settings
        }
    },
    components: {
        GlobalAboutListSingle,
        Carousel,
        Slide,
        Pagination,
    },
    props: {
        aboutObject: {type: Array}
    },
    methods: {
        getSingleTitle(single) {
            return single['title_'+this.$store.state.lang];
        },
        getSingleDescription(single) {
            return single['description_'+this.$store.state.lang];
        },
        next() {
            this.$refs.aboutListCarousel.next()
        },
        prev() {
            this.$refs.aboutListCarousel.prev()
        },
    }
};
</script>