<template>
    <div class="faqListSingle col-12">
        <a href="#" :data-oki="`#${ elemId }`" :aria-expanded="isOpen">
            {{ question }} <span class="icon icon-down"></span>
        </a>
        <div class="okiCollapse" :class="{ open: isOpen }" :id="`${elemId}_${$.uid}`" data-parent=".faqList">
            <div v-html="answer"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FaqListSingle',
        props: {
            elemId: {},
            question: {type: String},
            answer: {type: String},
            isOpen: {type: Boolean, default: false}
        }
    }
</script>