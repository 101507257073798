<template>
  <GlobalHeader />
  <main class="" >
    <router-view :key="$route.fullPath" />
  </main>

  <GlobalFooter :current-rout="this.$route" />
</template>

<style>
@import "assets/dist/css/vendors.min.css";
@import "assets/dist/css/main.min.css";
</style>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
export default {
  name: 'App',
  watch: {
    '$store.state.lang': function() {
      this.$i18n.locale = this.$store.state.lang;

      var langToPass = this.$root.$i18n.locale;

      document.documentElement.setAttribute("lang", langToPass);

      //TODO: adobeDataLayer push event
      //window.adobeDataLayer.language = langToPass;


      if(window.OneTrust != undefined)
        window.OneTrust.changeLanguage(langToPass);
      else {
        window.otInt = setInterval(function () {
          if(window.OneTrust != undefined) {
            window.OneTrust.changeLanguage(document.documentElement.getAttribute('lang'));

            clearInterval(window.otInt);
          }
        }, 100);
      }
    }
  },
  components: {
    GlobalHeader, GlobalFooter
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
  },
  beforeCreate() {
    this.$store.state.pagesLoading = [];
    this.$store.state.pagesNotFound = [];
  }
}
</script>