<template>
    <g class="boxes">
        <rect class="rectBarHover_elem" 
            :class="{active: this.isOpen}" 
            stroke-width="1" 
            stroke="transparent" 
            fill="rgba(255,255,255,0.01)"  
            rx="3" 
            :x="barX" 
            :y="barY" 
            :width="barWidth" 
            :height="barHeight" 
            v-click-outside="this.handleClose"
            @click.stop.prevent="handleClick"
            />
        <foreignObject class="text_wrap"
            :x="(barX - 21)" 
            :y="(barY - 22)" 
            width="60" 
            height="24"
            >
            <span :class="`text_${barX}_${barY}`">{{ this.splitNumber }}</span>
        </foreignObject>
    </g>
</template>

<script>
export default {
    name: 'AreaChartBarsHover',
    expose: ['handleClose', 'isOpen'],
    components: {},
    data() {
        return {
            isOpen: false
        }
    },
    props: {
        barX: {type: Number}, 
        barY: {type: Number}, 
        barWidth: {type: Number}, 
        barHeight: {type: Number},
        proportion: {type: Number}
    },
    methods: {
        handleClick() {
            document.querySelectorAll('.rectBarHover_elem.active').forEach(element => {
                element.classList.remove('active');
            });

            if(this.isOpen) {
                this.isOpen = false;
            } else {
                this.isOpen = true;
            }

            return false;
        },
        handleClose() {
            this.isOpen = false;
        }
    },
    computed: {
        splitNumber() {
            // var tempProp = parseInt(this.proportion / this.barHeight);
            return (this.proportion).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }
}
</script>