<template>
    <div class="linksListSingle">
        <a :href="href" class="btn btn-ghost btn-full btn-sq" @click="handleClick" target="_blank">
            <span class="btn-val">{{ title }}</span> 

            <GlobalTooltip :type-span="true" :tooltip-cont="tooltip" :tooltip-id="`ttp_${$.uid}`" />
        </a>
    </div>
</template>

<script>
    import GlobalTooltip from './GlobalTooltip.vue';

    export default {
    name: 'GlobalLink',
    components: { GlobalTooltip },
    props: {
        href: { type: String },
        tooltip: { type: String },
        title: { type: String }
    },
    methods: {
        handleClick(event) {
            console.log('GlobalLink click', event);
            return true;
        }
    }
}
</script>