<template>
    <div class="mapRating col-auto">
        <p>
            <span class="val">
                <!-- Rating wg. Mastercard® -->
                {{$t('map.legend.title.rating')}}
            </span> 
            <GlobalTooltip
                :tooltip-cont="$t('map.legend.tooltip')"
                :tooltip-id="`area_ttp_${$.uid}`" 
                />
        </p>
        <div class="mapRatingBar">
            <div class="boxWrapper" v-bind:data-score="this.getIndustryScore()">
                <div class="box"></div>
                <div class="box"></div>
                <div class="box "></div>
                <div class="box"></div>
                <div class="box"></div>
            </div>
            <div class="boxLegend">
                <span>
                    <!-- niski -->
                    {{$t('map.legend.low')}}
                </span>
                <span>
                    <!-- wysoki -->
                    {{$t('map.legend.hight')}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalTooltip from './GlobalTooltip.vue';

export default {
    name: 'GlobalMapLegend',
    components: { GlobalTooltip },
    props: {
        industryScore: {type: Number}
    },
    watch: {
        'industryScore': function() {
            console.log('industryScore', this.industryScore);
        }
    },
    methods: {
      getIndustryScore() {
            return this.industryScore;
        }
    }
}
</script>