<template>
  <div class="bg">
    <img class="desktop" src='@/assets/dist/img/map_big_mob_tiny.jpg' alt='Quiz'>
    <img class="mobile" src='@/assets/dist/img/map_big_tiny.jpg' alt='Quiz'>
  </div>
</template>
<script>
export default {
  name: 'QuizBg',
}
</script>