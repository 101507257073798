<template>
    <div class="partnersList partnersListSlider col-12 p-2 p2y-4-lg">
        <carousel 
            ref="partnersListCarousel"
            v-model="currentPartnersListSlide" 
            v-bind="currentPartnersListSettings" 
            :breakpoints="currentPartnersListBreakpoints"
            :transition="500"
            :wrap-around="false"
            >

            <slide v-for="(single, idx) in partnersObject" 
                :key="'globalPartnersListSingle_' + idx" >

                <GlobalPartnersListSingle
                    :id="'partnersListSliderSingleId_' + single.id"
                    :icon="single.image"
                    :title="this.getSingleTitle(single)"
                    :desc="this.getSingleDescription(single)"
                    :url="single.url"
                    :single="single"
                    />
            </slide>
            
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>

        <div class="partnersListSliderNav">
            <a href="#" @click.stop.prevent="prev"><span class='icon icon-prev'></span></a>
            <!-- <input type="number" min="0" max="9" v-model="currentSlide" /> -->
            <!-- <div>{{ currentSlide }}</div> -->
            <a href="#" @click.stop.prevent="next"><span class='icon icon-next'></span></a>
        </div>
    </div>
</template>

<script>
import GlobalPartnersListSingle from '@/components/GlobalPartnersListSingle.vue';

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'PartnersListSlider',
    data() {
        return {
            // carousel settings
            currentPartnersListSlide: 0,
            currentPartnersListSettings: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            currentPartnersListBreakpoints: {
                390: {
                    itemsToShow: 1.3,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'start',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
            // carousel settings
        }
    },
    components: {
        GlobalPartnersListSingle,
        Carousel,
        Slide,
        Pagination,
    },
    props: {
        partnersObject: {type: Array}
    },
    methods: {
        getSingleTitle(single) {
            return single['title_'+this.$store.state.lang];
        },
        getSingleDescription(single) {
            return single['content_'+this.$store.state.lang];
        },
        next() {
            this.$refs.partnersListCarousel.next()
        },
        prev() {
            this.$refs.partnersListCarousel.prev()
        },
    }
};
</script>