<template>
  <section class="map" :class="{'selected': this.isMobileAndOfferSelected()}">
      <div class="row">
        <div class="mapWrapper col-12 col-lg-">

          <MapWidget
            :pinsShouldBeShown="this.pinsShouldBeShown"
            @offer-selected="this.handleOfferSelected" 
            @square-selected="this.handleSquareSelection" 
            @offers-loaded="this.handleLoadedOffers"
            ref="mapWidget"
          />
          
          <GlobalMapTop @show-pins="this.handleShowHidePins" 
            @change-cat="this.handleCatChange"
            @change-city="this.handleCityChange"
            :square-info="this.selectedSquareInfo"
          />

          <div class="mapBot">
              <div class="row jcb jcs-lg aic">
                  <GlobalMapLegend :industry-score="this.getIndustryScore()" />

                  <div class="mapHelp">
                      <a href="#" class="btn" @click="this.reportHelp()" @click.prevent="handleModalOpen">{{$t('help')}}</a>
                  </div>
              </div>
          </div>

          <GlobalMapPopup
              :selected-estate="chosenEstate"
              ref="GlobalMapPopupEstate"
              @close-offer-popup="this.handleCloseOfferPopup"
          />
        </div>
        
        <MapSidebarNotSelected v-if="(this.$store.state.quiz.address == null && this.selectedSquareInfo == null)" />
        <MapSidebar
          v-if="(this.$store.state.quiz.address != null || this.selectedSquareInfo != null)"
          :class="{ animate : this.isMobile() }"
          :not-selected="false"
          :offers="this.offers" 
          :selected-square-info="this.selectedSquareInfo"
          :selected-category="this.chosenCategory"
          />
      </div>

      <GlobalModal ref="okiModal" />
    </section>
</template>
<script>


import GlobalMapLegend from "@/components/GlobalMapLegend.vue";
import GlobalMapPopup from "@/components/GlobalMapPopup.vue";
import GlobalMapTop from "@/components/GlobalMapTop.vue";
import MapWidget from "@/components/MapWidget.vue";
import GlobalModal from "./partials/GlobalModal.vue";
import MapSidebar from "./sections/MapSidebar.vue";
import MapSidebarNotSelected from "@/views/sections/MapSidebarNotSelected.vue";

export default {
  name: 'ResultsView',
  components: {
    MapWidget,
    GlobalMapTop,
    GlobalMapLegend,
    GlobalModal,
    MapSidebar,
    MapSidebarNotSelected,
    GlobalMapPopup
  },
  data() {
    return {
      results: null,
      mapContainer: null,
      pinsShouldBeShown: true,
      offers: [],
      chosenCategory: null,
      chosenCategoryScore: null,
      categoriesList: null,
      selectedSquareInfo: null,
      selectedOffer: false,
      chosenEstate: {
        img: 'placeImg01.jpg',
        name: 'Wola',
        adress: 'Rondo Ignacego Daszyńskiego',
        price: '4 600',
        link: '',
        details: [
          '55 m2',
          '1 pomieszczenie',
          'parter'
        ]
      }
    }
  },
  mounted() {
    if(this.$store.state.helpOpen && localStorage.getItem('isModalOpen') != 'hide')
      this.$refs.okiModal.handleOpen();
    else
      this.$refs.okiModal.handleClose();
  },
  methods: {
    getIndustryScore() {
      return this.chosenCategoryScore;
    },
    handleCloseOfferPopup() {
      this.selectedOffer = false;

    },
    isMobileAndOfferSelected() {
      if(this.selectedOffer === true && this.isMobile())
        return true;
      else
        return false;
    },
    handleOfferSelected(data) {

      this.chosenEstate = data.offer;
      this.selectedOffer = true;
      this.$refs.GlobalMapPopupEstate.handleOpen();
    },
    handleSquareSelection(data) {

      this.selectedSquareInfo = data;
      if (data.externalId != null || data.externalId != undefined) {

        this.$store.dispatch('fetchSquareScoring', {'squareId': data.externalId}).then((response) => {
          this.categoriesList = response;

          if(this.$store.state.quiz.industry != null) {
            for (var cat in response) {
              if (response[cat].categoryId == this.$store.state.quiz.industry) {
                // console.log('loop handleSquareSelection ',response[cat], this.$store.state.quiz.industry);
                return this.chosenCategoryScore = response[cat].score;
              }
            }
          } else {
            var bestICan = 0;
            for (var cat2 in response) {
              if(response[cat2].score > bestICan) {
                bestICan = response[cat2].score;
                this.chosenCategoryScore = response[cat2].score;
              }
            }

          }
          // console.log('handleSquareSelection score', response, this.$store.state.quiz.industry, this.chosenCategoryScore);
        });
      }
    },
    getSelectedRating() {
      for (var cat in this.categoriesList) {
        if(cat.categoryId == this.$store.state.quiz.industry) {
          return this.chosenCategoryScore = cat.score;
        }
      }
    },
    handleShowHidePins(data) {
      this.pinsShouldBeShown = data;
    },
    handleModalOpen() {
      this.$refs.okiModal.handleOpen();
    },
    handleLoadedOffers(offers) {
      this.offers = offers;
    },
    handleCatChange(data) {

      if(this.$store.state.industries.length > 0) {
        this.chosenCategory = this.$store.state.industries.find(o => o.id == data);
      } else {
        this.$store.dispatch('fetchIndustries').then((response) => {

          this.chosenCategory = response.find(o => o.id == data);
        });
      }

      this.$store.state.quiz.industry = this.chosenCategory.id;

      this.getSelectedRating();

      this.$refs['mapWidget'].setNeedToReselect(true);
      this.$refs['mapWidget'].loadSquares();


      // console.log('resultsView hahandleCatChangend', data, this.chosenCategory);
    },
    handleCityChange(data) {
      this.selectedSquareInfo = null;
      this.$store.commit('setQuizCity', data);
      this.$store.commit('setQuizAddress', null);
      this.$refs['mapWidget'].init();
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    reportHelp() {

      var clickInfoObject = {
        "ctaName" : "Help",
        "ctaType" : "Button",
        "ctaLocation" : "Body",
        "ctaTitle" : "Help - open modal",
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    }
  }

}
</script>