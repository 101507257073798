<template>
    <div class="areaDetailsSingle col-12 p2y-1 px-2">
        <div class="row">
            <div class="areaName col">
                <p class="h6">
                    {{ desc }} 
                    <GlobalTooltip v-if="valTooltip" 
                        :tooltip-cont="valTooltip" 
                        :tooltip-id="`area_ttp_${$.uid}`" 
                        />
                </p>
            </div>
            <div class="areaNr col-auto ta-r">
                <p class="h6" v-html="val"></p>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalTooltip from './GlobalTooltip.vue';

export default {
    name: 'AreaDetailsSingle',
    components: { GlobalTooltip },
    props: {
        desc: { type: String },
        val: {  },
        valTooltip: { type: String }
    },
    methods: {
    }
}
</script>