<template>
    <div class="okiModalTextSingle ta-c">
        <h2 class="h4_mob">{{ title }}</h2>

        <div v-html="desc"></div>
    </div>
</template>

<script>
    export default {
        name: 'ModalTextSingle',
        props: {
            title: {type: String},
            desc: {type: String}
        }
    }
</script>