<template>
    <div class="placeListSingle p2-1">
        <div class="row">
            <div class="img col-4">
                <a :href="url" target="_blank">
                  <img :src="image" :alt="title">
                </a>
            </div>
            <div class="desc col-8">
                <p class="loc">{{ title }}</p>
                <p class="adress">{{ address }}</p>
                <p class="price color_orange" v-if="price">{{ price.toLocaleString() }} zł</p>
                <p class="details">
                    <span class="val" :key="'placeListSingleDetails_' + id" v-for="(val, id) in details">{{ val }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GlobalPlaceListSingle',
        components: {},
        props: {
            url: { type: String},
            image: { type: String },
            title: { type: String },
            address: { type: String },
            price: { type: Number },
            details: { type: Array },
            single: { type: Object}
        },
        methods: {
        }
    }
</script>