<template>
    <span ref="tooltipComponent" v-if="typeSpan" class="tooltip" :class="{show: this.isOpen}" :id="this.tooltipId" v-click-outside="this.handleClose">
        <span class='icon icon-info' @click.stop.prevent="handleClick"></span> 
        <span class="tooltip_cont">{{ tooltipCont }} <span class="close" @click.stop.prevent="handleClose">x</span></span>
    </span>
    <a ref="tooltipComponent" v-else href="#" class="tooltip" :class="{show: this.isOpen}" :id="this.tooltipId" v-click-outside="this.handleClose">
        <span class='icon icon-info' @click.stop.prevent="handleClick"></span> 
        <span class="tooltip_cont">{{ tooltipCont }} <span class="close" @click.stop.prevent="handleClose">x</span></span>
    </a>
</template>

<script>
    export default {
        name: 'GlobalTooltip',
        expose: ['handleClose', 'isOpen', 'data'],
        props: {
            tooltipCont: {type: String},
            tooltipId: {type: String},
            typeSpan: {type: Boolean, default: false}
        },
        data() {
            return {
                isOpen: false
            }
        },
        mounted() {
            // var _t = this;
            // window.addEventListener('scroll', () => {
            //     console.log('tooltip scroll', _t.$refs, _t.$refs.tooltipComponent.data);
            //     if (_t.$refs.tooltipComponent.classList.contains('show')) {
            //         // _t.$refs.tooltipComponent.classList.remove('show');
            //         _t.$refs.tooltipComponent.handleClose();
            //         document.body.classList.remove('tooltipOpen');
            //     }
            // });

            // window.addEventListener('click', (e) => {
            //     // if (e.target != this.$el) {
            //     //     console.log('not this ', this.$el, ' tooltip', _t);
            //     // }
            //     // if ( document.body.classList.contains('tooltipOpen') ) {
            //     //     console.log('document.addEventListener click', e.target.contains(this.$el), this.$el);
            //     //     document.body.classList.remove('tooltipOpen');
            //     //     _t.handleClick();
            //     // }
            //     // if (e.target.contains(this.$el)) {
            //     //     console.log('tooltip mouted eventListener', e.target, this.$el);
            //     //     this.isOpen = false
            //     // }
            //     // if (!this.$el.contains(e.target)){
            //     // }
            // });
        },
        // unmounted() {
        //     window.removeEventListener('scroll', this.handleClose());
        // },
        methods: {
            handleClick() {
                // console.log('refs', this.$refs, 'this.$el', this.$el);
                // console.log('GlobalTooltip click', this.$.uid, this.isOpen, event);
                document.querySelectorAll('.tooltip.show').forEach(element => {
                    element.classList.remove('show');
                    // this.isOpen = false;
                    // document.body.classList.remove('tooltipOpen');
                });

                if(this.isOpen) {
                    this.isOpen = false;
                    document.body.classList.remove('tooltipOpen');
                } else {
                    this.isOpen = true;
                    document.body.classList.add('tooltipOpen');
                }

                return false;
            },
            handleClose() {
                this.isOpen = false;
                document.body.classList.remove('tooltipOpen');
            }
        }
    }
</script>