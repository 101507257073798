<template>
    <g class="boxes">
        <rect stroke-width="1" stroke="transparent" fill="#fff" rx="3" 
            :x="barX" 
            :y="barY" 
            :width="barWidth" 
            :height="barHeight" 
            />
    </g>
</template>

<script>
export default {
    name: 'AreaChartBars',
    components: {},
    props: {
        barX: {type: Number}, 
        barY: {type: Number}, 
        barWidth: {type: Number}, 
        barHeight: {type: Number}
    },
    methods: {
    }
}
</script>