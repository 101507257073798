<template>
    <section class="clear">
        <div class="wrap">
            <div class="row jcc">
                <div class="clearCont col-12 col-lg-10 px-2" v-html="this.content">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'WysiwygSection',
        props: {
          content: {
                type: String,
                default: ''
            }
        },
        components: {
        },
        methods: {
        }
    }
</script>
