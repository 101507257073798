<template>
    <div class="quizForm">
      <form>
        <div class="row">
          <div class="radioBox">
            <input type="radio" name="question01" id="question01-b" value="2" v-model="question">
            <label for="question01-b"><span class="val">{{ $t('quiz.step_1.option_2') }}</span></label>
          </div>
          <div class="radioBox">
            <input type="radio" name="question01" id="question01-a" value="1" v-model="question">
            <label for="question01-a"><span class="val">{{ $t('quiz.step_1.option_1') }}</span></label>
          </div>
          <div class="radioBox">
            <input type="radio" name="question01" id="question01-c" value="3" v-model="question">
            <label for="question01-c">
              <span class="val">{{ $t('quiz.step_1.option_3') }}</span> 
              <GlobalTooltip :tooltip-cont="$t('quiz.step_1.option_3_label')" :tooltip-id="`ttp_${$.uid}_3`" />
              <!-- <a @click="this.openTooltip(3)" class="tooltip" v-bind:class="{'show': this.option3tooltip}">
                <span class='icon icon-info'></span> 
                <span class="tooltip_cont">{{$t('quiz.step_1.option_3_label')}}<div class="close">x</div></span>
              </a> -->
            </label>
          </div>
          <div class="radioBox">
            <input type="radio" name="question01" id="question01-d" value="4" v-model="question">
            <label for="question01-d">
              <span class="val">{{ $t('quiz.step_1.option_4') }}</span> 
              <GlobalTooltip :tooltip-cont="$t('quiz.step_1.option_4_label')" :tooltip-id="`ttp_${$.uid}_4`" />
              <!-- <a @click="this.openTooltip(4)" class="tooltip" v-bind:class="{'show': this.option4tooltip}">
                <span class='icon icon-info'></span> 
                <span class="tooltip_cont">{{$t('quiz.step_1.option_4_label')}}<div class="close">x</div></span>
              </a> -->
            </label>
          </div>
          <div class="next col-12 col-lg-auto mx-a">
            <!-- <input type="submit" value="Dalej" disabled> -->
            <router-link @click="this.report()" :to="{name: 'quiz2', params: {'lang': this.$store.state.lang}}" class="btn btn-full" v-bind:class="{'disabled': this.$store.state.quiz.question_1 == null}">{{$t('quiz.go_next')}}</router-link>
          </div>
        </div>
      </form>
    </div>
</template>
<script>
import GlobalTooltip from '@/components/GlobalTooltip.vue';

export default {
  name: 'QuizStep1Widget',
  components: {
    GlobalTooltip
  },
  data() {
    return {
      question: null,
      option3tooltip: false,
      option4tooltip: false,
    }
  },
  mounted() {
    this.question = this.$store.state.quiz.question_1;
  },
  watch: {
    question() {
      this.$store.commit('setQuizQuestion1', this.question);
    }
  },
  methods: {
    // openTooltip(optionNumber) {

    //   var body = document.querySelector('body');
    //   var This = this;
    //   if(This['option' + optionNumber + 'tooltip'] == false) {
    //     This['option' + optionNumber + 'tooltip'] = true;

    //   } else {
    //     This['option' + optionNumber + 'tooltip'] = false;

    //   }
    //   body.classList.toggle('tooltipOpen');
    // },
    report() {
      var options = {
        1: "I’m looking for an idea",
        2: "I’m looking for a location",
        3: "I’m looking for an idea and a location",
        4: "I have an idea and a location"
      };
      var clickInfoObject = {
        "ctaName" : "Next",
        "ctaType" : "Button",
        "ctaLocation" : "Body",
        "ctaTitle" : "Quiz 1 | At what stage are you with your business?",
        "selectedOption": options[this.question]
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});

    }
  }
}
</script>