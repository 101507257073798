<template>
    <header class="">

      <div class="wrap">
        <div class="row jcb aic nowrap">
          <div class="logo col-auto pl-2">
            <router-link @click="this.handleSateliteClick($event)" :to="{ name: 'home', params: {'lang': this.$store.state.lang}}">
              <img src="@/assets/dist/img/logo.svg" v-bind:alt="$t('logo_alt')">
              <span class="title">{{ $t('top_title') }}</span>
            </router-link>
          </div>

          <GlobalBurger ref="burgerComponent" />
          <div class="menu col-12 col-lg-auto ml-auto">
            <GlobalMenuTop @menu-link-click="this.handleMenuClick" />
            <LangSwitcher location="header"/>
            <GlobalHeadAction class="mobile" @menu-link-click="this.handleMenuClick" />
          </div>

        </div>
      </div>

    </header>
</template>

<script>
import LangSwitcher from "@/components/LangSwitcher.vue";
import GlobalMenuTop from "./GlobalMenuTop.vue";
import GlobalBurger from "./GlobalBurger.vue";
import GlobalHeadAction from "./GlobalHeadAction.vue";
export default {
  name: 'GlobalHeader',
  components: {
    LangSwitcher,
    GlobalMenuTop,
    GlobalBurger,
    GlobalHeadAction
  },
  methods: {
    handleSateliteClick() {
      var clickInfoObject = {
        "ctaName" : "Logo Click",
        "ctaType" : "Button",
        "ctaLocation" : "header",
        "ctaTitle" : 'Go to home'
      };

      window.adobeDataLayer.push({ "event" : "ctaClick",
        "clickInfo" : clickInfoObject});
    },

    handleMenuClick() {
      // console.log('handleMenuClick trigger handleBurgerClick');
      if (window.innerWidth <= 1024) {
        console.log('handleMenuClick trigger handleBurgerClick mobile');
        this.$refs.burgerComponent.handleBurgerClick();
      }
    }
  }
}
</script>