<template>
    <div class="faq col-12 py-2" v-if="onMap && this.faqObject.length > 0">
        <div class="row">
            <div class="faqTitle col-12 px-2">
                <div class="row aic">
                    <h2 class="h3">
                        <span class="icon"><img src="@/assets/dist/img/faqIcon01.svg" :alt="$t('faq')"></span> <span class="val">{{$t('faq')}}</span>
                    </h2>

                    <router-link :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': 'faq'}}" class="more">
                        <strong>{{$t('show_all')}}</strong> <span class='icon icon-arrow_right'></span>
                    </router-link>
                </div>
            </div>

            <FaqList :faq-object="faqObject" />
        </div>
    </div>
    <section class="faq" :data-faq="this.faqObject.length" v-else-if="this.faqObject.length > 0">
        <div class="wrap">
            <div class="row jcc">
                <div class="faqTitle col-12 col-lg-4 px-2">
                    <div class="row aic">
                        <h2 class="h3"><span class="icon"><img src="@/assets/dist/img/faqIcon01.svg" alt="{{$t('faq')}}"></span> <span class="val">{{$t('faq')}}</span></h2>

                            <router-link :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': 'faq'}}" class="more">
                            <strong>{{$t('show_all')}}</strong> <span class='icon icon-arrow_right'></span>
                            </router-link>

                    </div>
                </div>
                <FaqList :faq-object="faqObject" class=" col-lg-8" />
            </div>
        </div>
    </section>
</template>

<script>
import FaqList from "@/views/partials/FaqList.vue";
export default {
    name: 'FaqSection',
    components: {
        FaqList
    },
    // data() {
    //     return {
    //         hasFaq: false,
    //     }
    // },
    // watch: {
    //     'hasFaq': function(e) {
    //         console.log('watch faqObject', e);
    //         if (e.length > 0) {
    //             this.hasFaq = true;
    //         }
    //     }
    // },
    props: {
        faqObject: {type: Array},
        onMap: {type: Boolean, default: false}
    }
}
</script>
