<template>
    <section class="quiz">
        <div class="bg" v-if="isSubpage">
            <img class="desktop" src="@/assets/dist/img/kontakt.jpg" alt="map">
            <img class="mobile" src="@/assets/dist/img/kontakt_mob.jpg" alt="map">
        </div>

        <div class="wrap" :class="{visible: this.$props.overflowWrap}">
            <div class="row">
                <div class="quizBgFix col-12 px-2">
                    <div class="row bg_gl">
                        <div class="quizTitle col-12 ta-c">
                            <p class="color_orange"><strong>{{$t('home.quiz.step_1.headline')}}</strong></p>
                            <h2 class="h4">{{$t('home.quiz.step_1.subtitle')}}</h2>
                        </div>
                        <QuizStep1Widget />

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//import GlobalTooltip from '@/components/GlobalTooltip.vue';
import QuizStep1Widget from "@/views/partials/QuizStep1Widget.vue";

export default {
    name: 'QuizSection',
    components: {
        //GlobalTooltip,
        QuizStep1Widget
    },
    props: {
        subpageBackground: Object,
        isSubpage: {type: Boolean, default: false},
        overflowWrap: {type: Boolean, default: false}
    },

}
</script>
