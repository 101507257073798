<template>
    <div v-if="this.$props.offers.length > 0" class="place col-12 py-2 px-1 bg_gl">
        <div class="row">
            <div class="placeTitle col-12 px-1">
                <div class="icon mb-1">
                    <img src="@/assets/dist/img/gratka.jpg" :alt="$t('quiz.results.real_estate.place_to_rent_headline')">
                </div>
                <h4>{{$t('quiz.results.real_estate.place_to_rent_headline')}}</h4>
                <p>{{$t('quiz.results.real_estate.place_to_rent_desc')}}</p>
            </div>

            <PlaceListSlider :place-object="this.getOffers()" />
        </div>
    </div>
</template>

<script>
import PlaceListSlider from '../partials/PlaceListSlider.vue';


export default {
    name: 'PlaceSection',
    components: {
        PlaceListSlider
    },
    props: {
      offers: {
        type: Array,
      }
    },
    data() {
        return {

        }
    },
    mounted() {
    },

    methods: {
        getOffers() {
            return this.$props.offers;
        }
    }
}
</script>
