<template>
    <section class="banner banner404">
        <div class="bg">
            <img class="desktop" src="@/assets/dist/img/kontakt.jpg" alt="map">
            <img class="mobile" src="@/assets/dist/img/kontakt_mob.jpg" alt="map">
        </div>
        <div class="wrap">
            <div class="row jcc">

                <div class="bannerTitle col-12 col-lg-5 px-2 p2y-2 p2y-5-lg ta-c">
                    <h1>
                        <span class="icon"><img src="@/assets/dist/img/404_2.svg" :alt="$t('not_found_title')"></span> 
                        <span class="val">{{ $t('not_found_title') }}</span>
                    </h1>
                    <p v-html="$t('not_found_caption')"></p>
                    <router-link :to="{ name: 'home', params: { 'locale': 'pl' } }" class="btn btn-full btn-auto-lg">
                        {{$t('not_found.back')}}
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: 'NotFoudView',
    mounted() {
        this.$store.dispatch('changeFooterVisibility', {
            'visibility': true
        });
    }
}
</script>