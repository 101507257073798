<template>
    <div class="partnersListSingle" :id="id">
        <div class="wrapper">
            <div class="img">
                <img :src="icon" :alt="title">
            </div>
            <div class="desc">
                <h3 class="h4"><a v-if="url != null" :href="url">{{ title }}</a></h3>
                <div class="descWrap" v-html="desc"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GlobalPartnersListSingle',
        components: {},
        props: {
            id: { type: String },
            icon: { type: String },
            title: { type: String },
            url: { type: String },
            desc: { type: String },
            single: { type: Object }
        },
        methods: {
            getPageSlug(single) {
                if(single.page != null) {
                    return single.page['slug_' + this.$store.state.lang];
                }

                return '';
            },
            getIMGPath(img) {
                return img ? require("@/assets/dist/img/" + img) : "";
            }
        }
    }
</script>