import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PageView from '@/views/PageView.vue'
import MapSummaryView from '@/views/MapSummaryView.vue'
import QuizStep1 from "@/views/QuizStep1.vue";
import QuizStep2 from "@/views/QuizStep2.vue";
import QuizStep3 from "@/views/QuizStep3.vue";
import QuizStep4 from "@/views/QuizStep4.vue";
import ResultsView from "@/views/ResultsView.vue";
import ConfigView from "@/views/ConfigView.vue";
//import ArticleView from "@/views/ArticleView.vue";
import { loadLanguageAsync } from '../i18n/index'
import {defaultLocale} from "../i18n/index"
import NotFoundView from "@/views/NotFoundView";
import store from '../store';

const routes = [
  {
    path: "/",
    redirect: `/${defaultLocale}`
  },
  {
    path: "/:lang(pl|en|uk)?",
    component: HomeView,
    name: 'home',
  },
  {
    path: "/:lang(pl|en|uk)/:slug",
    component: PageView,
    name: 'page',
  },
  {
    path: "/:lang(pl|en|uk)/:articleSlug(artykul|article)/:slug",
    component: PageView,
    name: 'article',
  },
  {
    path: "/:lang(pl|en|uk)/quiz/1",
    component: QuizStep1,
    name: 'quiz1',
  },
  {
    path: "/:lang(pl|en|uk)/quiz/2",
    component: QuizStep2,
    name: 'quiz2',
  },
  {
    path: "/:lang(pl|en|uk)/quiz/3",
    component: QuizStep3,
    name: 'quiz3',
  },
  {
    path: "/:lang(pl|en|uk)/quiz/4",
    component: QuizStep4,
    name: 'quiz4',
  },
  {
    path: "/:lang(pl|en|uk)/:resultsSlug(wyniki|results)",
    component: ResultsView,
    name: 'results',
  },
  {
    path: '/:lang(pl|en|uk)/:resultSlug(wynik|result)/:uuid',
    name: 'config',
    component: ConfigView
  },
  {
    path: "/:lang(pl|en|uk)/map",
    component: MapSummaryView,
    name: 'map',
  },
  {
    path: '/:pathMatch(.*)*',
    // path: '/:pathMatch(.*)',
    // path: '/:lang(pl|en|uk)/:pathMatch(.*)*',
    component: NotFoundView,
    name: 'NotFound',
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang == undefined ? defaultLocale : to.params.lang;
  const slug = to.params.slug;

  if(['home', 'page'].includes(to.name)) {
    if(slug === undefined)
      store.dispatch('fetchPage', '');
    else {
      store.dispatch('fetchPage', slug);
    }
  }
  if(['article'].includes(to.name)) {

    store.dispatch('fetchArticle', slug);
  }

  //if quiz then we need to validate if user can go to this step
  if(to.name.includes('quiz')) {
    var step = to.name.replace('quiz', '');

    var stepFrom = from.name != undefined ? parseInt(from.name.replace('quiz', '')) : null;

    if(step == 3 && parseInt(store.state.quiz.question_1) == 1) {//user is looking for idea so he need to skip industry
      if(stepFrom == 2)
        return next({ name: 'quiz4' });
      else if(stepFrom == 4)
        return next({ name: 'quiz2' });
    }

    if(isAllowedToQuizStep(step, store) == false) {
      window.top.location='/'+lang+'/quiz/1';
    }
  } else if(to.name.includes('results')) {
    if(isAllowedToResults(store) == false) {
      window.top.location='/'+lang+'/quiz/1';
    }
  }

  store.dispatch('changeLang', lang);

  // if (!['pl', 'en', 'uk'].includes(lang)) {
  //     return next(defaultLocale)
  // }



  loadLanguageAsync(lang).then(() => next());
});

router.afterEach((to) => {
  const lang = to.params.lang == undefined ? defaultLocale : to.params.lang;

  var adobeEvent = { "event" : "pageLoad",
    "pageInfo" : {
      "pageName": to.name + ('slug' in to.params ? ' - '+to.params.slug : ('articleSlug' in to.params ? ' - '+to.params.articleSlug : '')),//As per the page name strategy,
      "siteName": "Gdzie Jaki Biznes",
      "pageURL": to.fullPath,
      "region": "EU",
      "country": "PL",
      "language": lang, //language of the site
    }
  };

  setTimeout(function() {
    window.adobeDataLayer.push(adobeEvent);
  }, 1500);

});

function isAllowedToResults(store)
{
  if(isAllowedToQuizStep(4, store) == false)
    return false;

  if(store.state.quiz.budget == null && store.state.quiz.buy == null && store.state.quiz.noBudgetYet == null)
    return false;

  return true;
}

/**
 * 1 - branża: brak, lokalizacja: jest
 * 2 - branża: jest, lokalizacja: brak
 * 3 - branża: brak, lokalizacja: brak
 * 4 - branża: jest, lokalizacja: jest
 */
function isAllowedToQuizStep(step, store)
{

  if(step == 1) {
    return true;
  }
  if(step == 2) {
    if(store.state.quiz.question_1 == null)
      return false;

    return true;
  }
  if(step == 3) {
    if(isAllowedToQuizStep(2, store) == false)
      return false;

    if(store.state.quiz.city == null)
      return false;

    if(store.state.quiz.address == null && (store.state.quiz.question_1 == 1 || store.state.quiz.question_1 == 4))
      return false;

    return true;
  }

  if(step == 4) {
    if(isAllowedToQuizStep(3, store) == false)
      return false;

    if(store.state.quiz.industry == null && store.state.quiz.question_1 != 1)
        return false;

    return true;
  }

  return false;
}

export default router
