<template>
    <div class="areaDetails col-12">
        <div class="row">
            

            <AreaDetailsSingle v-for="(single, idx) in data" 
                :key="'areaDetailsSingle_' + idx"
                :desc="single.desc"
                :val="single.cat == 'noise' ? 
                    getNoiseDesc(single.val) : 
                    single.cat == 'air' ? 
                        getAirDesc(single.val) : 
                        single.val"

                :valTooltip="single.tooltip"
            />
        </div>
        <div class="row">
            <div class="areaDetailsTitle col-12 p2y-1 px-2">
                <strong class="color_g"><small>{{$t('area_details_powered_by')}}</small></strong>
            </div>
        </div>
    </div>
</template>

<script>
import AreaDetailsSingle from '@/components/AreaDetailsSingle.vue';

    export default {
        name: 'AreaDetails',
        data() {
            return {
                data: this.areaDetails,
                airQuality: [
                    {
                        val: [0,4.99],
                        label: this.$t('air_quality_1')
                    },
                    {
                        val: [5,9.99],
                        label: this.$t('air_quality_2')
                    },
                    {
                        val: [10,14.99],
                        label: this.$t('air_quality_3')
                    },
                    {
                        val: [15,24.99],
                        label: this.$t('air_quality_4')
                    },
                    {
                      val: [25,44.99],
                      label: this.$t('air_quality_5')
                    }
                ],
                noiseLevel: [
                    {
                        val: [0,44],
                        label: this.$t('noise_level_1')
                    },
                    {
                        val: [45,54],
                        label: this.$t('noise_level_2')
                    },
                    {
                        val: [55,69],
                        label: this.$t('noise_level_3')
                    },
                    {
                        val: [70,79],
                        label: this.$t('noise_level_4')
                    },
                    {
                        val: [80,999],
                        label: this.$t('noise_level_5')
                    }
                ]
            }
        },
        components: {
            AreaDetailsSingle
        },
        props: {
            areaDetails: {default: []}
        },
        watch: {
            areaDetails: function(val) {
                this.data = val;
            }
        },
        mounted() {

        },
        methods: {
            forcesUpdateComponent() {
              // our code
              this.$forceUpdate();  // Notice we have to use a $ here
              // our code
            },
            getNoiseDesc(el) {
                let val = ''
                this.noiseLevel.forEach((e) => {
                    if (el >= e.val[0] && el <= e.val[1]) {
                        val = e.label;
                    }
                });
                return val + "</br><small>(" + el + "db)</small>";
            },
            getAirDesc(el) {
                let val = '';
                this.airQuality.forEach((e) => {
                    if (el >= e.val[0] && el <= e.val[1]) {
                        val = e.label;
                    }
                });
                return val + "</br><small>(" + el + " µg/m3)</small>";
            }
        }
    }
</script>