<template>
    <div class="videoList videoListSlider col-12">
        <carousel 
            ref="VideoListCarousel"
            v-model="currentVideoListSlide" 
            v-bind="currentVideoListSettings" 
            :breakpoints="currentVideoListBreakpoints"
            :transition="500"
            :wrap-around="true"
            >

            <slide v-for="(single, idx) in videoObject" 
                :key="'globalVideoListSingle_' + idx" >
                <video width="100%" height="480" ref="videoListSliderSingle" controls poster="@/assets/dist/img/poster.png">
                    <source :src="single.url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </slide>
            
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>

        <div class="videoListSliderNav">
            <a href="#" @click.stop.prevent="prev"><span class='icon icon-prev'></span></a>
            <!-- <input type="number" min="0" max="9" v-model="currentSlide" /> -->
            <!-- <div>{{ currentSlide }}</div> -->
            <a href="#" @click.stop.prevent="next"><span class='icon icon-next'></span></a>
        </div>
    </div>
</template>

<script>

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'VideoListSlider',
    data() {
        return {
            // carousel settings
            currentVideoListSlide: 0,
            currentVideoListSettings: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            currentVideoListBreakpoints: {
                390: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                // 768: {
                //     itemsToShow: 1,
                //     snapAlign: 'start',
                // },
                // 1024: {
                //     itemsToShow: 3,
                //     snapAlign: 'start',
                // },
            },
            // carousel settings
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    props: {
        videoObject: {type: Array}
    },
    methods: {
        next() {
            this.$refs.VideoListCarousel.next()
        },
        prev() {
            this.$refs.VideoListCarousel.prev()
        },
        // handleProgress() {
        //     var percent = (this.$refs['videoListSliderSingle'].currentTime / this.$refs['videoListSliderSingle'].duration) * 100;
        //     console.log('videoListSliderSingle', percent);
        // },
    }
};
</script>