<template>
    <div class="burger" @click.prevent="handleBurgerClick" :class="{open: isOpen}">
        <span></span>
    </div>
</template>
<script>

export default {
    name: 'GlobalBurger',
    expose: ['handleBurgerClick'],
    components: {
    },
    props: {
    },
    data() {
        return {
            isOpen: false,
        }
    },
    mounted() {
    },
    methods: {
        handleBurgerClick() {
            if (!this.isOpen) {
                document.querySelector('.menu').classList.add('open');
                document.querySelector('body').classList.add('menuOpen');
            } else {
                document.querySelector('.menu').classList.remove('open');
                document.querySelector('body').classList.remove('menuOpen');
            }
            this.isOpen = !this.isOpen;
        }
    }
}
</script>