<template>
<div class="okiModal" :class="{open: this.isModalOpen}">
    <div class="wrap">
        <div class="row vmh-100 aic jcc">
            <div class="okiModalCont col-12 max-416 p-0">
                <a href="#" class="close" @click.prevent="handleClose" v-if="!isFirstSlide"><span class="icon icon-x2"></span></a>
                
                <div class="row">
                    <!-- <div class="okiModalImg okiModalImgFull col-12">
                        <img src="@/assets/dist/img/modalImg01_tiny.jpg" :alt="$t('onboarding.headline')">
                    </div> -->
                    <div class="okiModalContSlider col-12 px-0">
                        <OkiModalListSlider ref="refOkiModalSlider" 
                            :slides-data="this.getSlides()" 
                            @slder-on-change="this.handleIfLastLide"
                            @slder-on-upadte="this.handleIfFirstLide"
                            />
                    </div>
                    

                    <div class="okiModalAction col-12 ta-c p2t-1 pb-2 px-2">
                        <a href="#" class="btn btn-full" @click.prevent="handleNext" v-if="this.isLastSlide==false">{{ $t('quiz.results.help.next')}}</a>
                        <a href="#" class="btn btn-full" @click="this.report()" @click.prevent="handleClose" v-else >{{ $t('quiz.results.help.to_results')}}</a>
                        
                        <a href="#" @click.prevent="handleClose" v-if="!isFirstSlide">{{ $t('quiz.results.help.omit')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import OkiModalListSlider from './OkiModalListSlider.vue';

export default {
    name: 'GlobalModal',
    expose: ['handleOpen', 'handleClose'],
    components: {
        OkiModalListSlider
    },
    props: {
        // mapIntroData: {}
    },
    data() {
        return {
            isModalOpen: false,
            scenarious: [],
            isLastSlide: false,
            isFirstSlide: true
        }
    },
    mounted() {
        this.$store.dispatch('fetchOnboarding').then((response) => {
            this.scenarious = response;
        })

        window.addEventListener('popstate', () => {
          document.body.classList.remove('okiModalOpen');
        });
    },
    unmounted() {
        window.removeEventListener('popstate', () => {});
    },
  methods: {
        getSlides() {

          var scenario = this.$store.getters.getQuizPathName;
          if(scenario in this.scenarious) {
            return this.scenarious[scenario];
          }

        },
        handleClose() {
            if(this.isModalOpen) {
                this.isModalOpen = false;
                document.body.classList.remove('okiModalOpen');
                localStorage.setItem('isModalOpen', 'hide');
            }

            this.$store.commit('setHelp', false);
        },
        handleOpen() {
            if (!this.isModalOpen) {
                this.isModalOpen = true;
                document.body.classList.add('okiModalOpen');
                localStorage.setItem('isModalOpen', 'open');
                
                this.$refs.refOkiModalSlider.restartCarousel();
                this.$refs.refOkiModalSlider.handleInit();
            }
            this.$store.commit('setHelp', true);
        },
        handleIfFirstLide(e) {
            // console.log('handleIfFirstLide', e);
            if (e.currentSlide._value == 0) {
                this.isFirstSlide = true;
            } else {
                this.isFirstSlide = false;
            }
        },
        handleIfLastLide(e) {
            // console.log('handleIfLastLide', e);
            if (e.slidingToIndex == (e.slidesCount - 1)) {
                this.isLastSlide = true;
            } else {
                if (this.isLastSlide) {
                    this.isLastSlide = false;
                }
            }
        },
        handleNext() {
            this.$refs.refOkiModalSlider.next();
            this.report();
        },
        report() {

          var clickInfoObject = {
            "ctaName" : "Next",
            "ctaType" : "Button",
            "ctaLocation" : "Body",
            "ctaTitle" : window.document.querySelector('.carousel__slide--active .okiModalTextSingle h2').innerText,
          };

          window.adobeDataLayer.push({ "event" : "ctaClick",
            "clickInfo" : clickInfoObject});

        }
    }
}
</script>