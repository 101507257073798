<template>
    <div class="headAction col-12">
        <router-link :to="{'name': 'quiz1', params: {'lang': this.$store.state.lang}}" @click="this.handleClick" class="btn btn-full">
            <span class="btn-val">{{$t('quiz.start')}}</span>
        </router-link>
    </div>
</template>
<script>

export default {
    name: 'GlobalHeadAction',
    components: {
    },
    props: {
    },
    mounted() {
    },
    methods: {
        handleClick() {
            // console.log('headAction');

            this.$emit('menu-link-click');
        }
    }
}
</script>