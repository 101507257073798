<template>
    <div class="catListNew col-12 px-2 p2y-1">
      <h5 class="color_orange" v-html="this.$t('results.sidebar.selected_category.title')">
      </h5>
      <template v-if="this.$store.state.quiz.industry != null && this.scoring.length > 0">
        <ul>
          <li class="title">
                <span class="val">
                  {{$t('results.sidebar.selected_category_list.label.selected')}}
                  <!-- Wybrana kategoria -->
                </span>
                <span class="val">
                  {{$t('results.sidebar.selected_category_list.label.rating')}}
                  <!-- Potencjał wg Mastercard® -->
                </span>
          </li>
  
          <CatListSingle
              :key="'catListSingle_yours'"
              :isYour="true"
              :cat="this.getSelectedName()"
              :rating="this.getSelectedRating()"
          />
        </ul>
      </template>
    </div>

    <div v-if="this.scoring.length > 0" class="catListNew col-12 px-2 p2y-1 catListCustom">
        <ul>
            <li class="title">
                <span class="val" v-if="this.pathNumber == 1">
                    <!-- Najlepsze kategorie -->
                    {{$t('results.sidebar.selected_category_list.label.best')}}
                </span>
                <span class="val" v-else>
                    <!-- Inne kategorie -->
                    {{$t('results.sidebar.selected_category_list.label.other')}}
                </span>
                <span class="val">
                    <!-- Potencjał wg Mastercard® -->
                    {{$t('results.sidebar.selected_category_list.label.rating.other')}}
                </span>
            </li>

            <CatListSingle v-for="(single, idx) in this.scoring"
                :key="'catListSingle_' + idx"
                :counter="idx + 1"
                :elemId="single.id"
                :cat="this.getSingleName(single)"
                :rating="this.getSingleRating(single)"
                :hide="this.showHidden"
                 />
        </ul>
        <div class="catListNewMore" :class="{open :!showHidden}">
            <a href="#" @click.prevent="handleLoadMoreCat">
                <span v-if="showHidden">
                    <!-- Pokaż wszystkie  -->
                    {{$t('results.sidebar.category_list.more.show')}}
                </span>
                <span v-else>
                    <!-- Ukryj -->
                    {{$t('results.sidebar.category_list.more.hide')}}
                </span>
                <span class='icon icon-down_d'></span>
            </a>
        </div>
    </div>
</template>

<script>
    import CatListSingle from "@/components/CatListSingle.vue";
    export default {
        name: 'CatList',
        components: {
            CatListSingle
        },
        props: {
            // catObject: {type: Array},
            // selectedCat: {type: Object},
            newSelectedCat: {type: Object},
            newCatObject: {type: Object},
            squareInfo: {type: Object},
            pathNumber: {default: 0}
        },
        data() {
            return {
                showHidden: true,
                scoring: []
            }
        },
        watch: {
            'pathNumber': function() {
                // console.log('watch newSelectedCat', this.selectedCat, this.newSelectedCat, dat);
            },
            'newSelectedCat': function() {
                // console.log('watch newSelectedCat', this.selectedCat, this.newSelectedCat, dat);
            },
            'newCatObject': function(dat) {
                console.log('watch newCatObject', dat);
            },
            'squareInfo': function() {
              this.$store.dispatch('fetchSquareScoring', {'squareId': this.squareInfo.externalId}).then((response) => {
                this.scoring = response;
              });
            }
        },
        mounted() {

        },
        methods: {
          getSelectedName() {

            for(var indx in this.scoring) {
              if(this.scoring[indx].categoryId == this.$store.state.quiz.industry) {

                return this.getSingleName(this.scoring[indx]);
              }
            }
          },
          getSelectedRating() {
            for (var indx in this.scoring) {
              if(this.scoring[indx].categoryId == this.$store.state.quiz.industry) {
                return this.getSingleRating(this.scoring[indx]);
              }
            }
          },
          getSingleName(single) {

            if(this.$store.state.lang == 'pl')
              return single.categoryName;
            else if(this.$store.state.lang == 'en')
              return single.categoryNameEn;
            else if(this.$store.state.lang == 'uk')
              return single.categoryNameUk;
          },
          getSingleRating(single) {
            return this.$t('rating_'+single.score);
          },
          handleLoadMoreCat() {
              this.showHidden = !this.showHidden
          }
        }
    }
</script>