<template>
    <div class="mapSidebarZoomInfo col-12 ta-c p-2">
        <div class="icon desktop"><img :src="ico" alt="zoomMap"></div>
        <h5 class="mb-0">{{ info }}</h5>
    </div>
</template>

<script>
export default {
    name: 'SidebarInfo',
    components: {},
    props: {
        info: {type: String},
        ico: {type: String}
    },
    methods: {
    }
}
</script>