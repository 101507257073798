<template>
    <div class="placeList placeListSlider col-12">
        <carousel 
            ref="placeListCarousel"
            v-model="placeListCurrentSlide" 
            v-bind="placeListSettings" 
            :transition="500"
            :wrap-around="true"
            >

            <slide :key="'placeListSlider_' + idx" v-for="(single, idx) in this.getPlace()" >
                <GlobalPlaceListSingle
                    :url="single.url"
                    :image="single.image"
                    :title="single.title"
                    :address="single.district"
                    :price="single.price"
                    :details="this.getDetails(single)"
                    :single="single"
                    />
            </slide>
            
            <!-- <template #addons>
                <navigation />
                <pagination />
            </template> -->

        </carousel>
        
        <div class="placeListSliderNav">
            <a href="#" @click.stop.prevent="prev"><span class='icon icon-prev'></span></a>
            <!-- <input type="number" min="0" max="9" v-model="currentSlide" /> -->
            <!-- <div>{{ currentSlide }}</div> -->
            <a href="#" @click.stop.prevent="next"><span class='icon icon-next'></span></a>
        </div>
    </div>
</template>

<script>
import GlobalPlaceListSingle from '@/components/GlobalPlaceListSingle.vue';

// import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
    name: 'PlaceListSlider',
    data() {
        return {
            // carousel settings
            place: this.placeObject,
            placeListCurrentSlide: 1,
            placeListSettings: {
                itemsToShow: 1.2,
                snapAlign: 'start',
            },
            // breakpoints: {
            //     390: {
            //         itemsToShow: 1.3,
            //         snapAlign: 'end',
            //     },
            //     768: {
            //         itemsToShow: 2.5,
            //         snapAlign: 'center',
            //     },
            //     1024: {
            //         itemsToShow: 3,
            //         snapAlign: 'center',
            //     },
            // },
            // carousel settings
        }
    },
    components: {
        GlobalPlaceListSingle,
        Carousel,
        Slide
    },
    props: {
        placeObject: {type: Array}
    },
    watch: {

    },
    methods: {
        getDetails(single) {

          var list = [];
          if(single.square != null)
            list.push(single.square + ' m²');
          if(single.rooms != null) {
            if(single.rooms == 1)
              list.push(single.rooms + ' pomieszczenie');
            else if(single.rooms > 1 && single.rooms < 5)
              list.push(single.rooms + ' pomieszczenia');
            else
              list.push(single.rooms + ' pomieszczeń');
          }

          return list;

        },
        getPlace() {
          return this.placeObject;
        },
        next() {
            this.$refs.placeListCarousel.next()
        },
        prev() {
            this.$refs.placeListCarousel.prev()
        },
    }
};
</script>