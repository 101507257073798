<template>
    <div class="restart col-12 p-2">
        <router-link 
            @click="this.report()" 
            :to="{'name': 'quiz1', params: {'lang': this.$store.state.lang}}" 
            class="btn btn-full"><span class="val">{{$t('quiz.restart')}}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'SummaryRestart',
    components: {},
    props: {
    },
    methods: {
        report() {
        var clickInfoObject = {
          "ctaName" : "StartQuiz",
          "ctaType" : "Button",
          "ctaLocation" : "FixedFooter",
        };

        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});
      },
    }
}
</script>