<template>
    <section class="quiz quizMain quizStep01">
      <QuizBg />
      <QuizNav :active="1" />


      <div class="wrap">
        <div class="row jcc">
          <div class="col-12 col-lg-10 p-2">
            <div class="grid">
              <div class="quizTitle ta-c">
                <p class="color_orange"><strong>1/4</strong></p>
                <h2 class="h4_mob h3-lg">{{$t('quiz.step_1.headline')}}</h2>
              </div>
              <QuizStep1Widget />
            </div>
          </div>
        </div>
      </div>
    </section>


</template>
<script>
import QuizBg from "@/views/partials/QuizBg.vue";
import QuizNav from "@/views/partials/QuizNav.vue";
import QuizStep1Widget from "@/views/partials/QuizStep1Widget.vue";
export default {
  name: 'QuizStep1',
  components: {
    QuizNav,
    QuizBg,
    QuizStep1Widget
  }
}
</script>