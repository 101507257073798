<template>
  <div class="quizNav">
    <ul v-bind:data-active="this.active">
      <li class="" v-bind:class="{'active': active == 1}"><span class="dot">&nbsp;</span></li>
      <li class="" v-bind:class="{'active': active == 2}"><span class="dot">&nbsp;</span></li>
      <li class="" v-bind:class="{'active': active == 3}"><span class="dot">&nbsp;</span></li>
      <li class="" v-bind:class="{'active': active == 4}"><span class="dot">&nbsp;</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'QuizNav',
  props: ['active'],
}
</script>