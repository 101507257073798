<template>
    <section class="map">
        <div class="row">
            <div class="mapWrapper">
                <img class="desktop" src='@/assets/dist/img/zoomout_tiny_02.jpg' alt='map'>
                <img class="mobile" src='@/assets/dist/img/zoomout_mob_tiny_02.jpg' alt='map'>

                <GlobalMapTop />

                <div class="mapBot">
                    <div class="row jcb jcs-lg aic">
                        <GlobalMapLegend />

                        <div class="mapHelp">
                            <a href="#" class="btn" @click.prevent="handleModalOpen">Pomoc</a>
                        </div>
                    </div>
                </div>
            </div>

            <MapSidebar :not-selected="false" />

            <GlobalModal ref="okiModal" />
        </div>
    </Section>
            
</template>

<script>
import GlobalMapTop from '@/components/GlobalMapTop.vue';
import GlobalModal from './partials/GlobalModal.vue';
import MapSidebar from './sections/MapSidebar.vue';
import GlobalMapLegend from '@/components/GlobalMapLegend.vue';

export default {
    name: 'MapSummaryView',
    components: {
    MapSidebar,
    GlobalModal,
    GlobalMapLegend,
    GlobalMapTop
},
    data() {
        return {
            faq: []
        }
    },
    mounted() {
    },
    methods: {
        getFaq() {
            var page = this.getPage();

            if('faq' in page) {
                this.faq = [];
                for(var index in page.faq) {
                    this.faq.push({
                        'id': page.faq[index].id,
                        'question': page.faq[index]['question_' + this.$store.state.lang],
                        'answer': page.faq[index]['answer_' + this.$store.state.lang],
                    });
                }
                return this.faq;
            } else
                return [];
        },
        handleModalOpen() {
            this.$refs.okiModal.handleOpen();
        }
    }
}
</script>