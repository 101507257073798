<template>
    <li :class="{elemId, selected: isYour, hide: (counter > 5 && hide == true)}">
        <span class="cat">{{ cat }}</span>
        <span class="rat">{{ rating }}</span>
    </li>

</template>

<script>
    export default {
        name: 'CatListSingle',
        // data() {
        //     // return {
        //     //     // weak: this.weakCat
        //     // }
        // },
        props: {
            // weakCat: {type: Boolean, default: false},
            counter: {default: 1},
            elemId: {},
            cat: {},
            isYour: {},
            rating: {},
            hide: {}
        }
    }
</script>