import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index'
import ClickOutside from './directive/ClickOutsideDirective';



createApp(App).use(i18n).use(store).use(router).directive('click-outside', ClickOutside).mount('#app')

