<template>
    <div class="aboutListSingle">
        <div class="wrapper">
            <div class="img">
                <link rel='preload' as='image' :href="icon">
                <img :src="icon" :alt="title">
            </div>
            <div class="desc">
                <h3 class="h4">{{ title }}</h3>
                <div class="descWrap" v-html="desc">

                </div>
                <div class="more">
                    <a v-if="single.link != null" :href="single.link">
                      <span class="val">{{$t('read_more')}}</span> <span class='icon icon-arrow_right'></span>
                    </a>
                    <router-link :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': this.getPageSlug(single)}}" v-if="single.link == null && single.page != null">
                      <span class="val">{{$t('read_more')}}</span> <span class='icon icon-arrow_right'></span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutListSingle',
        components: {},
        props: {
            icon: { type: String },
            title: { type: String },
            desc: { type: String },
            single: { type: Object}
        },
        methods: {
          getPageSlug(single) {
            if(single.page != null) {
              return single.page['slug_' + this.$store.state.lang];
            }

            return '';
          }
        }
    }
</script>