<template>
  <div class="footer col-12 py-2" v-if="onMap">
    <div class="row jcc jcb-lg aic">
      <LangSwitcher location="footer" :on-map-sidebar="true"/>
      
      <div class="footMenu col-12 ta-c">
        <ul>
          <li v-for="item in this.menuItems" v-bind:key="item">
            <router-link v-if="item.local_page == true" @click="this.handleSateliteClick($event)" :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': this.getMenuItemSlug(item)}}">
              {{ this.getMenuItemLabel(item) }}
            </router-link>
            <a v-if="item.local_page == false" v-bind:href=this.getMenuItemLink(item) target="_blank">
            {{ this.getMenuItemLabel(item)}}
            </a>
          </li>
        </ul>
      </div>
      <div class="footLogo col-12 ta-c">
        <router-link @click="this.handleSateliteClick($event)" :to="{ name: 'home', params: {'lang': this.$store.state.lang}}">
          <img src="@/assets/dist/img/logo.svg" alt="logo">
          <span class="title">{{ $t('top_title') }}</span>
        </router-link>
      </div>
    </div>
  </div>
  <template v-else>
    <footer v-if="currentRout.name !== 'map' && currentRout.name !== 'results'" class="">
      <div class="wrap visible">
        <div class="row jcc jcb-lg aic">
          <LangSwitcher location="footer"/>
  
          <div class="footMenu col-12 col-lg-auto order-lg-10 ta-c">
            <ul>
              <li v-for="item in this.menuItems" v-bind:key="item">
                <router-link v-if="item.local_page == true" @click="this.handleSateliteClick($event)" :to="{ name: 'page', params: {'lang': this.$store.state.lang, 'slug': this.getMenuItemSlug(item)}}">
                  {{ this.getMenuItemLabel(item) }}
                </router-link>
                <a v-if="item.local_page == false" v-bind:href=this.getMenuItemLink(item)>
                {{ this.getMenuItemLabel(item)}}
                </a>
              </li>
              <li>
                <!-- OneTrust Cookies Settings button start -->
                <a href="javascript:Optanon.ToggleInfoDisplay();" class="sg-c-3 sg-f-bdy allowAllUsers" di-type="basic" di-class="Footer Click" di-id="manage cookie">
                  {{ $t('footer.manage.cookies') }}
                </a>
                <!-- OneTrust Cookies Settings button end -->
              </li>
            </ul>
          </div>
          <div class="footLogo col-auto col-lg-auto">
            <router-link @click="this.handleSateliteClick($event)" :to="{ name: 'home', params: {'lang': this.$store.state.lang}}">
              <img src="@/assets/dist/img/logo.svg" alt="logo">
              <span class="title">{{ $t('top_title') }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row footQuiz" >
        <div class=" col-12 ta-r">
          <div class="wrap">
            <div class="row">
              <div class="col-12 ta-r p-2">
                <router-link @click="this.report()" :to="{'name': 'quiz1', params: {'lang': this.$store.state.lang}}" class="btn"><span class="btn-val">{{$t('quiz.start')}}</span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </footer>
  </template>
</template>
<script>

import LangSwitcher from "@/components/LangSwitcher.vue";
export default {
    name: 'GlobalFooter',
    components: {
      LangSwitcher
    },
    props: {
      currentRout: {default: false},
      onMap: {type: Boolean, default: false}
    },
    data() {
      return {
        menuItems: [],
        lastPosition: -1
      }
    },
    created() {
      window.addEventListener("scroll", this.updateScroll);
      window.addEventListener('load', this.updateScrollWithTimeout);
      window.addEventListener('resize', this.updateScrollWithTimeout);
    },
    mounted() {

      var thisComponent = this;
      this.$store.dispatch('fetchMenu', 'Bottom').then(items => {
        thisComponent.menuItems = items;

      });

    },
    methods: {
      updateScrollWithTimeout() {
        setTimeout(function(){
          this.updateScroll
        },300);
      },
      updateScroll() {
        // this.lastPosition = window.pageYOffset;
        this.lastPosition = window.scrollY;
        var fP = document.querySelector('.footQuiz');
        var main = document.querySelector("main > section");

        if (main) {
          if (this.lastPosition >= (main.parentElement.offsetTop + main.offsetHeight/3) && this.lastPosition > 0) {

            if (fP && !fP.classList.contains('fixed')) {
              fP.classList.add('fixed');
            }
          } else {
            if (this.lastPosition >= (main.parentElement.offsetTop + main.offsetHeight) && this.lastPosition > 0) {
              if (fP && !fP.classList.contains('fixed')) {
                fP.classList.add('fixed');
              }
            } else {
              if(fP)
                fP.classList.remove('fixed');
            }
          }
        } else {
          if(fP)
            fP.classList.add('fixed');
          // if (this.lastPosition >= (wh/2)) {
          //   if (!fP.classList.contains('fixed')) {
          //     fP.classList.add('fixed');
          //   }
          // } else {
          //   fP.classList.remove('fixed');
          // }
        }

      },
      getMenuItemSlug(menuItem) {
        if(menuItem.local_page != true)
          return '';

        return this.getMenuItemLink(menuItem);

      },
      getMenuItemLink(menuItem) {
        if(this.$store.state.lang == 'en')
          return menuItem.link_en;
        else if(this.$store.state.lang == 'pl')
          return menuItem.link_pl;
        else if(this.$store.state.lang == 'uk')
          return menuItem.link_uk;
      },
      getMenuItemLabel(menuItem) {
        if(this.$store.state.lang == 'en')
          return menuItem.label_en;
        else if(this.$store.state.lang == 'pl')
          return menuItem.label_pl;
        else if(this.$store.state.lang == 'uk')
          return menuItem.label_uk;
      },
      handleSateliteClick(event) {
        var clickInfoObject = {
          "ctaName" : "Menu Item Click",
          "ctaType" : "Link",
          "ctaLocation" : "BottomMenu",
          "ctaTitle" : event.target.innerText,
        };

        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});
      },
      report() {
        var clickInfoObject = {
          "ctaName" : "StartQuiz",
          "ctaType" : "Button",
          "ctaLocation" : "FixedFooter",
        };

        window.adobeDataLayer.push({ "event" : "ctaClick",
          "clickInfo" : clickInfoObject});
      },
    }
}
</script>