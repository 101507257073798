<template>
    <div class="newsListSingle">
        <div class="wrapper">
            <div class="img">
                <router-link :to="{name: 'article', params: {'lang': this.$store.state.lang, 'articleSlug': 'artykul', 'slug': slug}}">
                    <img :src="img" :alt="title">
                </router-link>
            </div>
            <div class="desc">
                <h3 class="h4">
                    <router-link :to="{name: 'article', params: {'lang': this.$store.state.lang, 'articleSlug': 'artykul', 'slug': slug}}">
                        {{ title }}
                    </router-link>
                </h3>
                <div class="descWrap" v-html="desc"></div>
                <div class="more">
                    <router-link :to="{name: 'article', params: {'lang': this.$store.state.lang, 'articleSlug': 'artykul', 'slug': slug}}">
                      <span class="val">{{$t('read_more')}}</span> 
                      <span class='icon icon-arrow_right'></span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NewsListSingle',
        components: {},
        props: {
            img: { type: String },
            title: { type: String },
            desc: { type: String },
            slug: { type: String },
            single: { type: Object }
        },
        methods: {
        }
    }
</script>