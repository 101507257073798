<template>
    <div class="links col-12 py-2">
        <div class="row aic">
            <div class="linksTitle col-12 px-2 pb-2">
                <h2 class="h3_mob">
                    <span class="icon"><img src="@/assets/dist/img/linksIcon02.svg" :alt="$t('local_business_websites')"></span> 
                    <span class="val">{{$t('local_business_websites')}}</span>
                </h2>

                <p>{{$t('check_infos_on_local_business_websites')}}</p>

                <a v-if="this.city && this.city.businessPortal != null" :href="this.getLink()" target="_blank" class="btn btn-full">
                    <span class="val">{{$t('check')}}</span>
                    <span class='icon icon-link2'></span>
                </a>
                <a v-if="this.city && this.city.businessPortal2 != null" :href="this.getLink2()" target="_blank" class="btn btn-full mt-2">
                  <span class="val">{{$t('check')}}</span>
                  <span class='icon icon-link2'></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TownInfoSection',
    components: {
    },
    props: {
    },
    data() {
        return {
          city: null
        }
    },
    mounted() {

      if(this.$store.state.cities.length > 0) {
        this.loadCity();
      } else {
        this.$store.dispatch('fetchCities').then(function() {
          this.loadCity();

        });
      }
    },
    methods: {
      getLink() {
        if(this.city && this.city.businessPortal != '') {
          return this.city.businessPortal;
        }
      },
      getLink2() {
        if(this.city && this.city.businessPortal2 != '') {
          return this.city.businessPortal2;
        }
      },
      loadCity() {

        if(this.$store.state.cities.length > 0) {
          for(var i in this.$store.state.cities) {
            if(this.$store.state.cities[i].id == this.$store.state.quiz.city.id) {
              this.city = this.$store.state.cities[i]
            }
          }
        }
      }
    }

}
</script>
