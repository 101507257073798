<template>
    <TopBannerSection />

    <QuizSection class="visible" :overflow-wrap="true" />

    <AboutSection class="pb-0" />

    <AboutSectionListSlider :about-main="this.about" v-if="this.hasAbout" />

    <SimpleSection class="simpleMain " :banners="this.getBanners()" />

    <NewsSection v-if="this.hasNews" />

    <!-- <ArticlesSection /> -->

    <FaqSection :faq-object="this.getFaq()" class="bg_gl"/>

    <LinksSection :links-list="this.links" />
</template>

<script>
import LinksSection from "@/views/sections/LinksSection.vue";
import FaqSection from "@/views/sections/FaqSection.vue";
import TopBannerSection from "@/views/sections/TopBannerSection.vue";
import SimpleSection from "./sections/SimpleSection.vue";
import AboutSection from "./sections/AboutSection.vue";
import AboutSectionListSlider from "./sections/AboutSectionListSlider.vue";
import QuizSection from "./sections/QuizSection.vue";
// import ArticlesSection from "@/views/sections/ArticlesSection.vue";
import NewsSection from "@/views/sections/NewsSection.vue";

export default {
  name: 'HomeView',
  components: {
    LinksSection, FaqSection, SimpleSection, AboutSection, QuizSection, TopBannerSection, AboutSectionListSlider,
    // ArticlesSection,
    NewsSection
},
  mounted() {
    this.$store.dispatch('fetchLinks').then(links => {
      this.links = links;
    });
    this.$store.dispatch('fetchAboutSections').then(sections => {
      this.about = sections;
      if (sections.length > 0) {
        this.hasAbout = true;
      }
    });
    this.$store.dispatch('fetchArticles').then((data) => {
        if (data.length > 0) {
          this.hasNews = true;
        }
    });
  },
  data() {
    return {
      links: [],
      faq: [],
      about: [],
      hasAbout: false,
      hasNews: false
    }
  },
  // watch: {
  //   'faq': function(val) {
  //     console.log('watch Faq homeView', this.faq, val);
  //   },
  //   'hasFaq': function(val) {
  //     console.log('watch hasFaq homeView', this.hasFaq, val);
  //   }
  // },
  methods: {
    getBanners() {
      if(('home' in this.$store.state.pages) == false)
        return [];

      var page = this.$store.state.pages['home'];

      if('textBanners' in page) {
        return page.textBanners;
      } else
        return [];
    },
    getFaq() {
      if(('home' in this.$store.state.pages) == false)
        return [];

      var page = this.$store.state.pages['home'];
      if('faq' in page) {
        this.faq = [];
        for(var index in page.faq) {
          this.faq.push({
            'id': page.faq[index].id,
            'question': page.faq[index]['question_' + this.$store.state.lang],
            'answer': page.faq[index]['answer_' + this.$store.state.lang],
          });
        }
        // this.hasFaq = true;
        return this.faq;
      } else
        return [];
    }
  }

}
</script>
