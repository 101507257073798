<template>
<div></div>
  <section class="notFound">
    <div class="wrap">
      <div class="row jcc jcs-lg">
        <div class="col-11 col-lg-6 offset-lg-1 ta-c ta-l-lg">
          <h1>{{ $t('search...') }}</h1>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import router from '../router';

export default {
  beforeCreate() {

    this.$store
        .dispatch('getConfig', this.$route.params.uuid)
        .then(config => {

          if(config != undefined && 'question_1' in config) {
            if(config.buy == true || config.noBudgetYet || config.budget != null)
              router.push({name: 'results', params: {'lang': this.$store.state.lang, 'resultsSlug': (this.$store.state.lang == 'pl' ? 'wyniki' : 'results')}});
            else
              router.push({name: 'quiz1', params: {'lang': this.$store.state.lang}});
          } else {
            router.push({name: 'NotFound'});
          }
        }).catch(error => {
            console.log(error);
            router.push({name: 'NotFound'});

    });
  },

}
</script>