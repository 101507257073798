<template>
    <section class="about">
        <div class="wrap">
            <div class="row">
                <!-- <div class="aboutTitle col-12 px-2">
                    <h2 class="h3">
                        <span class="icon"><img src="@/assets/dist/img/aboutIcon01.svg" v-bind:alt="$t('about_tool')"></span>
                        <span class="val">{{$t('about_tool')}}</span>
                    </h2>
                    <div class="mobile pt-1">
                        <p>{{$t('about_tool_description')}}</p>
                        <a href="#" class="more"><span class="val">{{ $t('get_more_info')}}</span> <span class='icon icon-arrow_right'></span></a>
                    </div>
                </div>
                <AboutList class="m2b-4" :about-object="aboutMain" /> -->
                
                <div class="aboutTitle col-12 px-2">
                    <h2 class="h3">
                        <span class="icon">
                            <img src="@/assets/dist/img/aboutIcon01.svg" :alt="$t('home.about_slider_headline')">
                        </span>
                        <span class="val">{{$t('home.about_slider_headline')}}</span>
                    </h2>
                </div>
                <AboutListSlider :about-object="aboutMain" />
            </div>
        </div>
    </section>
</template>

<script>
// import AboutList from '../partials/AboutList.vue';
import AboutListSlider from '../partials/AboutListSlider.vue';

export default {
    name: 'AboutSectionListSlider',
    components: {
        // AboutList,
        AboutListSlider
    },
    mounted() {
        // this.aboutMainLimited = this.aboutMainLimited.splice(0,3);
    },
    data() {
        return {
            aboutMainLimited: this.aboutMain
        }
    },
    props: {
        aboutMain: {type: Array}
    }
}
</script>
