<template>
    <section class="news">
        <div class="wrap">
            <div class="row">
                <div class="newsTitle col-12 px-2">
                    <h2 class="h3">
                        <span class="icon"><img src="@/assets/dist/img/newsImg01.svg" alt="{{ $t('articles.headline') }}"></span> 
                        <span class="val">{{ $t('articles.headline') }}</span>
                    </h2>
                </div>
                
                <NewsListSlider />
            </div>
        </div>
    </section>
</template>

<script>
import NewsListSlider from '../partials/NewsListSlider.vue';

export default {
    name: 'NewsSection',
    props: {
    },
    components: {
        NewsListSlider
    }
}
</script>
