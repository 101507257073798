<template>
    <div class="faqList col-12 px-2">
        <div class="row jcc">

            <FaqListSingle v-for="(single, idx) in faqObject" 
                :key="'faqListSingle)' + idx"
                :elemId=single.id
                :question="single.question" 
                :answer="single.answer"
                @click.prevent="handleClick(single.id)"
                :isOpen="this.isOpen(single.id) ? true : false"
                />

        </div>
    </div>
</template>

<script>
    import FaqListSingle from "@/components/FaqListSingle.vue";
    export default {
        name: 'FaqList',
        data() {
            return {
                currentOpen: []
            }
        },
        components: {
            FaqListSingle
        },
        props: {
            faqObject: {type: Array}
        },
        methods: {
            isOpen(index) {
                return this.currentOpen.indexOf(index) !== -1;
            },
            handleClick(index) {
                if(this.isOpen(index)) {
                    this.currentOpen.splice(this.currentOpen.indexOf(index), 1);
                } else {
                    this.currentOpen = [];
                    this.currentOpen.push(index);
                }
                // console.log('handleClick', index, this.currentOpen);
            }
        }
    }
</script>