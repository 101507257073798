<template>
  <section class="banner bannerContact">
    <div class="bg">
      <img class="desktop" src="@/assets/dist/img/kontakt.jpg" alt="map">
      <img class="mobile" src="@/assets/dist/img/kontakt_mob.jpg" alt="map">
    </div>
    <div class="wrap">
      <div class="row">
        <div class="bannerTitle col-12 col-lg-5 px-2 p2y-2 p2y-5-lg">
          <h1><span class="icon"><img src="@/assets/dist/img/faqIcon01.svg" v-bind:alt="$t('write_to_us')"></span> <span class="val">{{$t('write_to_us')}}</span></h1>
          <p>{{$t('contact_form.description')}}</p>
        </div>
        <div class="bannerForm col-12 col-lg-7">
          <div v-if="this.sent==false" class="wrapper">
            <form @submit="this.handleSubmit">
              <div class="row">
                <div class="col col-12" v-bind:class="{'error': this.validation.name===false}">
                  <label for="name">{{$t('contact_form.name')}}</label>
                  <input type="text" v-model="name" name="name" id="name" @focus="this.validation.name=null;" v-bind:placeholder="$t('contact_form.name')">
                  <span v-if="this.validation.name===false" class="info"><span class="icon icon-warning"></span> {{$t('contact_form.incorrect_value')}}</span>
                </div>
                <div class="col col-12" v-bind:class="{'error': this.validation.email===false}">
                  <label for="email">{{$t('contact_form.email')}}</label>
                  <input type="email" v-model="email"  name="email" id="email" @focus="this.validation.email=null;" v-bind:placeholder="$t('contact_form.email')">
                  <span v-if="this.validation.email===false" class="info"><span class="icon icon-warning"></span> {{$t('contact_form.incorrect_value')}}</span>
                </div>
                <div class="col col-12" v-bind:class="{'error': this.validation.subject===false}">
                  <label for="topic">{{$t('contact_form.subject')}}</label>
                  <select name="topic" id="topic" v-model="subject" @focus="this.validation.subject=null;" >
                    <option value="">{{$t('contact_form.subject')}}</option>
                    <option value="general">{{$t('contact_form.subject.general')}}</option>
                    <option value="complaint">{{$t('contact_form.subject.complaint')}}</option>
                    <option value="question">{{$t('contact_form.subject.question')}}</option>
                    <option value="other">{{$t('contact_form.subject.other')}}</option>
                  </select>
                  <span v-if="this.validation.subject===false" class="info"><span class="icon icon-warning"></span> {{$t('contact_form.incorrect_value')}}</span>
                </div>
                <div class="col col-12" v-bind:class="{'error': this.validation.content===false}">
                  <label for="message">{{$t('contact_form.content')}}</label>
                  <textarea name="message" v-model="content"  id="message" @keyup="this.contentCounter=this.content.length" @focus="this.validation.content=null;"  v-bind:placeholder="$t('contact_form.content')" maxlength="500"></textarea>
                  <span class="maxLen">{{contentCounter}} / 500</span>
                  <span v-if="this.validation.content===false" class="info"><span class="icon icon-warning"></span> {{$t('contact_form.incorrect_value')}}</span>
                </div>
                <div class="checkbox col col-12" v-bind:class="{'error': this.validation.terms===false}">
                  <input type="checkbox" v-model="terms" value="1" name="lorem01" id="lorem01">
                  <label for="lorem01">{{$t('contact_form.terms')}}</label>
                </div>
                <div class="col-12">
                  <input type="submit" class="full" v-bind:value="$t('contact_form.send')">
                </div>
              </div>
            </form>
          </div>
          <div v-if="this.sent==true" class="wrapper">
            <div class="col-12 col-lg-6 py-5 ta-c-lg">
              <h2 class="h3">{{  $t('contact_form.thanks') }}</h2>
              <p>{{$t('contact_form.thanks_description')}}</p>
              <router-link :to="{name: 'quiz1', 'lang': this.$store.state.lang}" class="btn btn-full">{{$t('go_to_quiz')}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
    name: 'ContactView',
    data() {
      return {
        name: '',
        email: '',
        subject: '',
        content: '',
        terms: '',
        contentCounter: 0,
        validation: {
          name: null,
          email: null,
          subject: null,
          content: null,
          terms: null,
        },
        sent: false
      }
    },
    watch: {
      terms() {
        this.validation.terms = null;
      }
    },
    methods: {
      handleSubmit(event) {
        event.preventDefault();
        this.validation.name = this.name.length > 0;
        this.validation.email = this.email.length > 0;
        this.validation.subject = this.subject.length > 0;
        this.validation.content = this.content.length > 0;
        this.validation.terms = this.terms == true;
        if(this.validation.name && this.validation.email && this.validation.subject && this.validation.content && this.validation.terms) {
          this.$store.dispatch('sendContactForm', {
            name: this.name,
            email: this.email,
            subject: this.subject,
            content: this.content
          }).then((response) => {

            if(response.data.status == 'ok') {
              this.sent=true;
            } else {
              this.sent=false;
              if('errors' in response.data) {
                for(var index in response.data.errors) {
                  this.validation[response.data.errors[index].field] = false;
                }
              }
            }

          });
        }
      }
    }
}
</script>