<template>
  <svg class="line animate" width="300" height="32" viewBox="0 0 300 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="300" height="32" rx="4" fill="white"/>
    <g class="line_anim" transform="scale(1, 1) translate(-300, 0) " >
      <rect width="300" height="32" rx="4" fill="url(#paint0_linear_1037_4)">
        <!-- <animate attributeName="width" from="0" to="calc(300 * .8)" dur="0.5s" fill="freeze" /> -->
        <!-- calc/vars dond work in animation on safari -->
        <animate id="anim-width" attributeName="width" from="0" :to="this.getDownloadSpeedProportion()" dur="0.5s" fill="freeze" />
      </rect>
    </g>
    <defs>
      <linearGradient id="paint0_linear_1037_4" x1="299.49" y1="15.9996" x2="-6.44169e-06" y2="15.9998" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF671B"/>
        <stop offset="0.494792" stop-color="#F38B00"/>
        <stop offset="1" stop-color="#FFC81F"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'DownloadWidget',
  props: {
    downloadSpeed: {
      type: Number,
      default: 0
    }
  },
  watch: {
    downloadSpeed: function() {
      this.$forceUpdate();
      window.document.querySelector("#anim-width").beginElement();
    }
  },
  methods: {
    getDownloadSpeedProportion() {
      return this.downloadSpeed;
    }
  }
}
</script>