<template>
    <section class="partners">
        <div class="wrap">
            <div class="row">
                <div class="partnersTitle col-12 p-2 pb-0-lg p2t-4-lg">
                    <h2 class="h3">
                        <span class="icon">
                            <img v-if="this.icon!=null && this.icon!=''" :src="this.icon" :alt="this.headline">
                        </span> 
                        <span class="val">{{ this.headline }}</span>
                    </h2>
                </div>

                <template v-if="this.isMobile" >
                    <PartnersListSlider :partners-object="partners" v-if="partners.length >= 2" />
                    <div class="partnersList col-12 p-2 p2y-4-lg" v-else>
                        <div class="row jcc">
                            <GlobalPartnersListSingle class="" v-for="(single, idx) in partners"
                                :key="'partnersListSingle_' + idx"
                                :id="'partnersListSingleId_' + single.id"
                                :icon="single.image"
                                :title="this.getSingleTitle(single)"
                                :desc="this.getSingleDescription(single)"
                                :url="single.url"
                                :single="single"
                                />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <PartnersListSlider :partners-object="partners" v-if="partners.length > 3" />
                    <div class="partnersList col-12 p-2 p2y-4-lg" v-else>
                        <div class="row jcc">
                            <GlobalPartnersListSingle class="" v-for="(single, idx) in partners"
                                :key="'partnersListSingle_' + idx"
                                :id="'partnersListSingleId_' + single.id"
                                :icon="single.image"
                                :title="this.getSingleTitle(single)"
                                :desc="this.getSingleDescription(single)"
                                :url="single.url"
                                :single="single"
                                />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import GlobalPartnersListSingle from '@/components/GlobalPartnersListSingle.vue';
import PartnersListSlider from '../partials/PartnersListSlider.vue';

export default {
    name: 'PartnersSection',
    data() {
        return {
            isMobile: true,
        };
    },
    props: {
        partners: {type: Array},
        headline: {type: String},
        icon: {type: String}
    },
    components: {
        PartnersListSlider, GlobalPartnersListSingle
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize, {passive: true});
    },
    unmounted() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize', this.onResize, {passive: true});
        }
    },
    watch: {
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 1024;
        },
        getSingleTitle(single) {
            return single['title_'+this.$store.state.lang];
        },
        getSingleDescription(single) {
            return single['content_'+this.$store.state.lang];
        }
    }
}
</script>
